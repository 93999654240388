<template>
    <v-row>
        <v-col>
            <v-card class="pa-4">
                <v-card-title>{{ entity.title }}</v-card-title>
                <v-card-text>
                    <SiteTabLinks />
                    <v-row class="pa-4">
                        <v-btn color="primary" class="mr-3" @click="create">
                            <v-icon>mdi-plus</v-icon> Добавить
                        </v-btn>
                    </v-row>
                    <v-data-table
                        :headers="headers"
                        :items="entities"
                        class="elevation-1 mt-4"
                        :loading="loading"
                        disable-pagination
                        hide-default-footer
                    >
                        <template v-slot:[`item.created_at`]="{ item }">
                            {{ item.created_at | moment('DD.MM.YYYY HH:mm') }}
                        </template>
                        <template v-slot:[`item.title`]="{ item }">
                            <span class="title">{{ item.title }}</span>
                        </template>
                        <template v-slot:[`item.open`]="{ item }">
                            <a :href="`/sites/${entity.id}/entities/${item.id}/detail`">
                                <v-icon color="primary">mdi-eye</v-icon>
                            </a>
                        </template>
                    </v-data-table>
                </v-card-text>
                <v-card-actions class="pa-4 d-flex justify-space-between">
                    <div>
                        <v-btn color="primary" class="mr-3" @click="create">
                            <v-icon>mdi-plus</v-icon> Добавить
                        </v-btn>
                        <v-btn color="light" class="mr-3" @click="goBack" >Назад</v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
    import store from '../../store';
    import { mapState } from 'vuex';
    import SiteTabLinks from '../../components/sites/SiteTabLinks';

    export default {
        name: 'EntitiesList',
        components: {
            SiteTabLinks
        },
        data: () => ({
            loading: true,
            headers: [
                { text: 'Дата', value: 'created_at' },
                { text: 'Заголовок', value: 'title' },
                { text: 'Адрес', value: 'address' },
                { text: '', align: 'right', value: 'open' },
            ],
        }),
        computed: {
            ...mapState('auth', ['user']),
            ...mapState('sites', {
                entity: state => state.entity
            }),
            ...mapState('entities', ['entities']),
        },
        async mounted() {
            this.loading = true;
            const id = this.$route.params.id;
            await store.dispatch('siteTypes/fetch');
            if(id) {
                await store.dispatch('sites/get', {id});
                await store.dispatch('entities/fetch', {site_id: id});
            }
            this.loading = false;
        },
        methods: {
            async create() {
                await this.$router.push({ name: 'sites-entities-create', params: { site_id: this.$route.params.id } });
            },
            async open(id) {
                await this.$router.push({ name: 'sites-entities-detail', params: { site_id: this.$route.params.id, id } });
            },
            goBack() {
                const { id } = this.$route.params;
                if(id) {
                    this.$router.push(`/sites/${id}/detail`);
                } else {
                    this.$router.push('/sites');
                }
            },
        }
    }
</script>

<style scoped>
    .title {
        font-size: 16px !important;
        line-height: 18px;
        font-weight: 700;
    }
</style>